<template>
  <div fill>
    <v-snackbar v-model="snackbar" app centered>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <PageBanner bannerText="Select a term" />
    <v-container class="pa-0 ma-0">
      <div v-for="term in termsToSelect" :key="term.id">
        <v-row no-gutters v-if="showTerm(term)">
          <v-col cols="12" class="tile-border">
            <v-card
              flat
              class="mx-auto align-center justify-center d-flex"
              height="75"
              @click="selectCoursesForATerm(term)"
            >
              <v-container class="pa-0">
                <v-row align="center">
                  <v-col cols="3">
                    <v-img
                      :src="
                        require('../assets/term-icon-' +
                          term.season +
                          (term.reservationLimit > 0 ? '' : '-inactive') +
                          '.svg')
                      "
                      contain
                      height="47"
                      style="margin-left:auto;margin-right:auto;"
                    >
                    </v-img>
                  </v-col>
                  <v-col cols="7">
                    <div
                      :class="
                        'term-title ' +
                          (term.reservationLimit > 0
                            ? 'primary--text py-0'
                            : 'secondary--text py-0')
                      "
                    >
                      {{ term.title }}
                    </div>
                    <div
                      :class="
                        'term-info ' +
                          (term.reservationLimit > 0
                            ? 'primary--text py-0'
                            : 'secondary--text py-0')
                      "
                    >
                      {{ term.info }}
                    </div>
                  </v-col>
                  <v-col cols="2">
                    <div
                      class="term-badge"
                      v-if="courseCount(term) > 0 && confirmed(term)"
                    >
                      <div>
                        {{ courseCount(term) }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import PageBanner from "@/components/PageBanner";
import { countItems } from "@/api/reservations";
import logdown from "logdown";
import _ from "lodash";

const logger = logdown("SelectATerm");
// logger.state.isEnabled = true;

export default {
  name: "SelectATerm",

  components: {
    PageBanner,
  },

  data: () => ({
    snackbar: false,
    snackbarText: "Not available",
  }),

  computed: {
    ...mapGetters({
      appMode: "getAppMode",
    }),

    ...mapState({
      termsToSelect: (state) => state.allTerms,
      selectedTerm: (state) => state.selectedTerm,
      userConfirmations: (state) => state.userConfirmations,
    }),
  },

  methods: {
    showTerm(term) {
      return !term.locked || this.courseCount(term) > 0;
    },
    getConfirmation(term) {
      logger.debug("getConfirmation", term);
      return _.get(this.userConfirmations, [term]);
    },
    courseCount(term) {
      return countItems(this.$store.getters.getUserCourses, term.id);
    },
    confirmed(term) {
      // logger.log(this.userConfirmations, term)
      const confirmation = _.get(this.userConfirmations, _.get(term, "id"));
      return _.size(confirmation) > 0;
    },
    ...mapMutations({
      selectPage: "setSelectedPage",
      selectTerm: "setSelectedTerm",
    }),
    selectCoursesForATerm: function(term) {
      const confirmation = this.getConfirmation(term.id);
      const termCourseCount = this.courseCount(term);
      logger.debug(
        "selectCoursesForATerm",
        term.id,
        confirmation,
        termCourseCount
      );
      if (confirmation && termCourseCount > 0) {
        this.selectTerm(term);
        this.selectPage("TermReview");
      } else {
        if (term.reservationLimit > 0) {
          this.selectTerm(term);
          if (termCourseCount > 0) {
            this.selectPage("TermCourses");
          } else {
            this.selectPage("ATermLanding");
          }
        } else {
          this.snackbar = true;
          this.snackbarText =
            "The " + term.title + " term is not available for reservation";
        }
      }
    },
  },

  created() {
    // this.$store.dispatch("fetchAllTerms");
  },
};
</script>

<style scoped>
.active-title {
  color: blue;
}
.tile-border {
  border-bottom: 1px solid #333333;
}
.term-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: red;
  /* color: #9B51E0; */
}
.term-info {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Purple 1 */
}

.term-badge {
  border-radius: 3px;
  border: solid 1px;
  background-color: #9b51e0;
  color: #ffffff;
  font-size: 12px;
  height: 22px;
  width: 22px;
  letter-spacing: 0;
  line-height: 1;
  min-width: 20px;
  /* padding: 4px 6px; */
  pointer-events: auto;
  text-indent: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
