var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"fill":""}},[_c('v-snackbar',{attrs:{"app":"","centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-container',{staticClass:"pa-0 ma-0"},_vm._l((_vm.terms),function(term){return _c('div',{key:term.id},[(!term.locked && (_vm.mode == 'select' || (_vm.mode == 'display' && _vm.display(term.id))))?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"tile-border",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto align-center justify-center d-flex",attrs:{"flat":"","height":"75"},on:{"click":function($event){return _vm.selectTerm(term)}}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-img',{staticStyle:{"margin-left":"auto","margin-right":"auto"},attrs:{"src":require('../assets/term-icon-' +
                        term.season +
                        (term.reservationLimit > 0 ? '' : '-inactive') +
                        '.svg'),"contain":"","height":"47"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{class:'term-title ' +
                        (term.reservationLimit > 0
                          ? 'primary--text py-0'
                          : 'secondary--text py-0')},[_vm._v(" "+_vm._s(term.title)+" ")]),_c('div',{class:'term-info ' +
                        (term.reservationLimit > 0
                          ? 'primary--text py-0'
                          : 'secondary--text py-0')},[_vm._v(" "+_vm._s(term.info)+" ")])]),_c('v-col',{attrs:{"cols":"3"}},[(_vm.mode == 'display')?_c('div',{staticClass:"term-toggle"},[_c('div',[_vm._v("change")])]):_vm._e(),(
                      _vm.courseCount(term) > 0 &&
                        _vm.confirmed(term) &&
                        _vm.mode != 'display'
                    )?_c('div',{staticClass:"term-badge"},[_c('div',[_vm._v(" "+_vm._s(_vm.courseCount(term))+" ")])]):_vm._e()])],1)],1)],1)],1)],1):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }